import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import { Banner, Buttons, ContentWrapper } from "../components"
import {
  Typography,
  Box,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material"
import { IoIosArrowDown } from "react-icons/io"
import LocalizedLink from "../components/LocalizedLink"
import { MarkdownContainer } from "../components/ContentWrapper"

const JobsPageTemplate = ({ data }) => {
  const { title, subtitle, jobs_title, jobs, banner } =
    data.markdownRemark.frontmatter

  return (
    <>
      <ContentWrapper title={title} subtitle={subtitle}>
        <Typography
          component="h2"
          fontSize="1.75rem"
          color="#242424"
          fontWeight="bold"
          borderBottom="1px solid #C4C4C4"
          pb="3px"
          mb={4}
        >
          {jobs_title}
        </Typography>
        <Box>
          {jobs && jobs.map((item, i) => <Job key={`job-${i}`} {...item} />)}
        </Box>
      </ContentWrapper>
      <Banner data={banner} />
    </>
  )
}

JobsPageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default JobsPageTemplate

export const pageFragment = graphql`
  fragment JobsPage on MarkdownRemarkFrontmatter {
    title
    subtitle
    jobs_title
    jobs {
      childMarkdownRemark {
        html
        frontmatter {
          title
          description
          date_since
          button_title
        }
      }
    }
    banner {
      title
      subtitle
      button {
        title
        link
      }
    }
  }
`

function parseDate(str) {
  var mdy = str.split("/")
  return new Date(mdy[2], mdy[0] - 1, mdy[1])
}

function datediff(first, second) {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.round(
    (parseDate(second) - parseDate(first)) / (1000 * 60 * 60 * 24)
  )
}

const getDateString = date =>
  `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`

const Job = ({ childMarkdownRemark: { frontmatter, html } }) => {
  const { title, description, date_since, button_title } = frontmatter
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"))

  const [daysSince, setDaysSince] = useState(null)

  // Set days since job was created
  useEffect(() => {
    const date_today = getDateString(new Date())
    const date_past = getDateString(new Date(date_since))
    const days_difference = datediff(date_past, date_today)
    setDaysSince(days_difference)
  }, [date_since])

  return (
    <Accordion
      sx={{
        boxShadow: "0px 0px 15px rgba(0, 0, 101, 0.08)",
        borderRadius: "10px!important",
        mb: "2rem!important",
        "&::before, *": {
          bgcolor: "transparent",
        },
        p: "5px",
      }}
    >
      <AccordionSummary
        expandIcon={<IoIosArrowDown size={22} color="#000065" />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        sx={{ alignItems: { base: "flex-start", md: "center" } }}
      >
        <Stack
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ base: "stretch", md: "center" }}
          flex="1"
          pr={3}
          spacing={2}
        >
          <Stack spacing="4px">
            <Typography color="primary">{title}</Typography>
            <Typography color="#242424" fontWeight="bold" fontSize="1.125rem">
              {description}
            </Typography>
            <Typography fontSize="0.75rem" color="#707070">
              {daysSince} days ago
            </Typography>
          </Stack>
          <LocalizedLink
            to="/contact"
            style={{
              textDecoration: "none",
              marginRight: isSmallScreen && "calc(-1 * (1.5rem + 22px))",
            }}
          >
            <Buttons.Primary
              style={{ width: "100%", zIndex: 2 }}
              title={button_title}
            />
          </LocalizedLink>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <MarkdownContainer markdownHTML={html} />
      </AccordionDetails>
    </Accordion>
  )
}
